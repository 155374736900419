/* eslint-disable no-undef */
import React from 'react';
import './App.css';
import { Router } from 'react-router-dom';
import Routes from './navigation/RouterConfig';
import history from './navigation/history';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import item from "./assets/images/favIcon.ico";
import { config } from './utils/Helper';

firebase.initializeApp(config);
function App() {
    return (
        <Router history={history}>
            <ToastContainer />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Wickedb</title>
                <meta name="description" content="Welcome To Wickedb" />
                <link rel="apple-touch-icon" href={item} />
                <link rel="icon" href={item} />


            </Helmet>
            <Routes />
        </Router>
    );
}

export default App;
