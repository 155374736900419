export const isLogin = () => {

    let token = localStorage.getItem('jwt');
    // console.log(token)
    if (token === "null") {
        token = null;
    }

    if (token !== null) {

        // console.log(localStorage.getItem('jwt'));
        return true;
    }

    return false;
}

export const isBar = () => {

    let loginAs = localStorage.getItem('loginAs');

    if (loginAs === "null") {
        loginAs = null;
    }

    if (loginAs !== null) {

        if (loginAs === "BAR") {
            return true;
        } else {
            return false;
        }



    }

    return false;
}

export const isMenu = () => {

    let loginAs = localStorage.getItem('loginAs');

    if (loginAs === "null") {
        loginAs = null;
    }

    if (loginAs !== null) {

        if (loginAs === "CLIENT") {
            return true;
        } else {
            return false;
        }



    }

    return false;
}


export const config = {
    apiKey: "AIzaSyAFAG0STRC3knHuMsk6rs0WNbpqtsfrCJA",
    authDomain: "toontutor.firebaseapp.com",
    databaseURL: "https://toontutor-default-rtdb.firebaseio.com/",
    projectId: "toontutor",
    storageBucket: "toontutor.appspot.com",
    messagingSenderId: "191310619833"

};
