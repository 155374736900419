const HOST = process.env.REACT_APP_API_HOST;
// const HOST = 'http://localhost:8000/';
const API_URL = HOST + "api/";



export const AUTH_CHECK = API_URL + "auth/me";
export const FETCH_PRODUCT = API_URL + "get/breavage";
export const FETCH_EVENT = API_URL + "get/event";
export const FETCH_ADS= API_URL + "get/ads";
export const FETCH_BAR_PRODUCT = API_URL + "get/bar/breavage";
export const FETCH_BAR_PRODUCTS = API_URL + "get/bars/products";
export const FETCH_CASH_TRIGGER = API_URL + "get/cash/trigger";
export const FETCH_CASH_BAR_TRIGGER = API_URL + "get/cash/bar/trigger";
export const SAVE_CASH_TRIGGER = API_URL + "save/cash/trigger";



export const LOGIN_URL = API_URL + "login";
export const ORDER_URL = API_URL + "order";
export const TIME_URL = API_URL + "time";
export const GET_TIME_URL = API_URL + "get/time";
export const GET_EVENT_CRASH_URL = API_URL + "get/eventCrash";
export const GET_TIME_NOTIFICATION = API_URL + "get/notification";
export const LAST_ORDER_URL = API_URL + "get/last/order/time";

export const LOGOUT = API_URL + "logout";



