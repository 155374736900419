import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { displayMessages, signIn } from "../utils/ApiMethod";
import { useHistory } from 'react-router';
import { useRecoilState } from "recoil";
import { cashTrigger } from "../utils/atom";



export default function Login({ type }) {
    const history = useHistory();
    const [user_name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [cash_trigger, setTrigger] = useRecoilState(cashTrigger);



    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            Login();
        }

    }
    const Login = async () => {


        if (user_name === "" || user_name == null) {
            return toast.error('UserName Required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }
        if (password === "" || password == null) {
            return toast.error('Password Required', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        }

        let user = await signIn(user_name, password, type?type:"Bar");



        if (user.status) {
            displayMessages("success", "Login Successfully");
            let data = user.data;
            localStorage.setItem("access_token", data.token.token);
            localStorage.setItem('jwt', data.token.token);
            localStorage.setItem('event_id', data.event.id);
            localStorage.setItem('bar_id', data.bar.id);
            localStorage.setItem('loginAs', data.loginAs);
            localStorage.setItem('crash_risk', data.event.crash_risk);
            localStorage.setItem('crash_duration', data.event.crash_duration);
            localStorage.setItem('text_Size', data.event.text_size);
            localStorage.setItem('interstitial_ad', data.event.interstitial_ad);
            localStorage.setItem('interstitial_ad_for', data.event.interstitial_ad_start_time);
            localStorage.setItem('screen_color', data.event.screen_color);
            localStorage.setItem('price_rounding', data.event.price_rounding);
            localStorage.setItem('calculation_interval', data.event.calculation_interval);
            localStorage.setItem('manual_crash',0);
            localStorage.setItem('auto_crash',0);
            localStorage.setItem('is_event_crash',0);
            localStorage.setItem('is_interval_complete',0);
            


            setTrigger(data.event.crash_risk);

            if (data.loginAs === "BAR") {

                window.location.reload("/barscreen");
            } else {

                window.location.reload("/clientscreen");
            }
        }

    };


    return (
        <div className="login_sec">
            <ToastContainer />
            <div className="btn-container">
                <i className="fa fa-moon-o" aria-hidden="true"></i>
                <label className="switch btn-color-mode-switch">
                    <input type="checkbox" name="color_mode" id="color_mode" value="1" />
                    <label htmlFor="color_mode" data-on="" data-off="" className="btn-color-mode-switch-inner"></label>
                </label>
                <i className="fa fa-sun-o" aria-hidden="true"></i>
            </div>
            <div className="container">
                <div className="login_panel">
                    <h3>welcome back</h3>

                    <div className="bar_number">
                        <i className="fa fa-user"></i>
                        <input type="text" name="user_name" value={user_name} onKeyPress={(e)=>handleKeyPress(e)} onChange={(e) => setName(e.target.value)} placeholder="Enter User Name" />
                    </div>
                    <div className="bar_number" style={{ marginTop: "5px" }}>
                        <i className="fa fa-lock"></i>
                        <input type="password" name="password" value={password} onKeyPress={(e) => handleKeyPress(e)} onChange={(e) => setPassword(e.target.value)} placeholder="Enter Password" />
                    </div>
                    <button onClick={() => Login()}>Login as {type?type:"Bar"}</button>
                    {/* <a href="#">Forget Password</a> */}
                </div>
            </div>
        </div >
    )

}
