import React from "react";
import { Switch } from "react-router-dom";



import Login from "../Pages/Login";
import Menu from "../Pages/Menu.js";
import Bar from "../Pages/Bar.js";


import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';




export default function Routes() {
    return (
        <Switch>
            <PublicRoute path="/" exact restricted={true} component={Login} />
            <PrivateRoute path="/clientscreen" component={Menu} />
            <PrivateRoute path="/barscreen" exact restricted={true} component={Bar} />

        </Switch>
    );
}

