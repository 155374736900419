/* eslint-disable no-undef */

import { useEffect, useLayoutEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import { Image } from '../components/Image';
import { getAds, getCashTrigger, getClientProduct, getEvent, getNotification, getTimer, saveTimer ,getEventCrash } from "../utils/ApiMethod";
import { config, isLogin } from '../utils/Helper';
import Login from './Login';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useRecoilState } from 'recoil';
import { cashTrigger } from '../utils/atom';
import moment from "moment";
import { Zoom } from "react-slideshow-image";
import {
    CircularProgressbar,
    buildStyles
  } from "react-circular-progressbar";
import "../assets/css/index.css";
const timerProps = {
    isPlaying: true,
    size: 120,
    strokeWidth: 6
};
const daySeconds = 86400;
// firebase.initializeApp(config);

const zoomOutProperties = {
    duration: 1000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    scale: 0.4,
    arrows: false
};
let interval;
export default function Menu() {
    const [percentage, setPercentage] = useState(0);
    const [access_token, seToken] = useState("");
    const [event_id, setEventID] = useState(0);
    const [cash_trigger, setTrigger] = useRecoilState(cashTrigger);
    const [minuteSeconds, setMintSecond] = useState(0);
    const [remainingTime, setRemainingTime] = useState(0); // use UNIX timestamp in seconds

    const days = Math.ceil(remainingTime / daySeconds);
    const daysDuration = days * daySeconds;
    const [color, setColor] = useState("#111");
    const [textSize, settextSize] = useState("12");
    const [startTimer, setStartTimer] = useState(1);
    const [pageLoad, setPageLoad] = useState(0);


    const [records, setRecord] = useState([]);
    const [images, setImages] = useState([]);

    const [adDisplay, setDisplay] = useState(0);
    const [actualTime, setActualTime] = useState(0);
    const [cashDuration,setCasDuration]=useState(0);
    let is_event_crash = localStorage.getItem("is_event_crash");
    // let is_interval_complete = localStorage.getItem("is_interval_complete");
    let auto_crash = localStorage.getItem("auto_crash");
    useEffect(() => {
        async function preInfoCall() {

            let token = localStorage.getItem("jwt");
            let eventID = localStorage.getItem("event_id");
            let crash_risk = localStorage.getItem("crash_risk");
          

            let text_Size = localStorage.getItem("text_Size");
            let interstitial_ad_for = localStorage.getItem("interstitial_ad_for");
            settextSize(text_Size);
            let screen_color = localStorage.getItem("screen_color");

            if (screen_color === "DarkMode") {
                $(`.progress span`).css("color", "#90ee90");
                $('body').addClass('dark-preview');
                $('body').removeClass('white-preview');
                setColor("#90ee90");
            } else {
                $('body').addClass('white-preview');
                $('body').removeClass('dark-preview');
                $(`.progress span`).css("color", "black");
                setColor("#111");
            }



            let interstitial_ad = localStorage.getItem("interstitial_ad");
            setTimeout(function () {
                openModal();
           }, (interstitial_ad * 60) * 1000);



            // setTimeout(function () {
            //     setInterval(() => {
            //         setDisplay(1);

            //         setTimeout(function () {
                        // setInterval(() => {
                        // setDisplay(0);

                        // }, interstitial_ad_for  * 100);



            //         }, interstitial_ad_for * 1000);

            //     }, (interstitial_ad * 60) * 100);




            //     }, (interstitial_ad * 60)*100);




            setTrigger(crash_risk);

            let accessToken = "";
            if (access_token === null) {
                if (token === null) {
                    seToken(token);
                    setEventID(eventID)
                }
            } else {
                accessToken = token;
            }
            is_event_crash = localStorage.getItem("is_event_crash");

            let response = await getClientProduct(accessToken, eventID ,is_event_crash);
            let ads = await getAds(eventID, accessToken);
            setImages(ads);
            if (response.success === true) {
                localStorage.setItem("is_event_crash",response.is_crash);
                setInterval(() => {
                    calculatePercentage(accessToken, eventID);
                }, 5000);
                getTimeStamp();
                setRecord(response.data);
            } else {
                // localStorage.clear();
                // return <Redirect to="/" />;
            }
        }

        preInfoCall();
        initFirebase();



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access_token, seToken]);

    useLayoutEffect(() => {
        //check local token or something

        // $(".progress-bar").ProgressBar();

    }, []);
    var initFirebase = function () {
        firebase.database().ref("/notifications").on("value", function (snapshot) {
            // setStartTimer(0);
            getTimeStamp();
            checkNotification();

        });
    }

    async function checkNotification() {

        if (pageLoad === 0) {
            let event_id = localStorage.getItem('event_id');
            let token = localStorage.getItem("jwt");

            let res = await getNotification(event_id);

            let type = res.data.data.type;

            if (type === "Event Update") {

                let event = await getEvent(event_id, token);
                localStorage.setItem('crash_risk', event.data.data.crash_risk);
                localStorage.setItem('crash_duration', event.data.data.crash_duration);
                localStorage.setItem('text_Size', event.data.data.text_size);
                localStorage.setItem('screen_color', event.data.data.screen_color);

                settextSize(event.data.data.text_size);
                setTrigger(event.data.data.crash_risk);

            }
            // if (type === "Beverage Update") {
            //     is_event_crash = localStorage.getItem("is_event_crash");
            //     let response = await getClientProduct(token, event_id , is_event_crash);

            //     if (response.success === true) {
            //         setRecord(response.data);
            //     }

            // }
            // if (type === "Order") {
            //     calculatePercentage(token, event_id);

            // }
            // if (type === "CountDown Update") {
            //     getTimeStamp();

            // }
            // if (type === "CountDown Create") {
            //     getTimeStamp();

            // }

        } else {

            setPageLoad(1);
        }









    }

    async function getTimeStamp() {



        let event_id = localStorage.getItem('event_id');
        let crash_duration = localStorage.getItem("crash_duration");
        let is_event_crash = localStorage.getItem("is_event_crash");
        let calculation_interval = localStorage.getItem("calculation_interval");
        // let is_interval_complete = localStorage.getItem("is_interval_complete");
        setCasDuration(crash_duration);
        let actualSec = (crash_duration * 60);
        
        

        if(event_id===null){
            return;
        }

        let crash = await getEventCrash(event_id);
        let res = await getTimer(event_id);
        if(crash.data.data == 2){
            setCasDuration(calculation_interval);
            actualSec = (calculation_interval * 60);
        }

        let oldTime = res.data.data.time;
        setActualTime(oldTime);
        let currentTime = moment().unix();

        let diff = currentTime - oldTime;

        let timeLeft = actualSec - diff;
     



        if (timeLeft > 0) {
            setMintSecond(timeLeft);
            setRemainingTime(0);
            // setStartTimer(1);

        } else {
            // getTimeStamp();
            //
        }










    }

    async function onChangeColor(e) {

        // console.log();

        if (e.target.checked) {
            $(`.progress span`).css("color", "#90ee90");
            $('body').addClass('dark-preview');
            $('body').removeClass('white-preview');
            setColor("#90ee90");
        } else {
            $('body').addClass('white-preview');
            $('body').removeClass('dark-preview');
            $(`.progress span`).css("color", "black");
            setColor("#111");
        }


    }
     function openModal() {

        let interstitial_ad_for = localStorage.getItem("interstitial_ad_for");

       setDisplay(1);
       console.log(1,2);



        setTimeout(function () {

            setDisplay(0);
            getTimeStamp();


            startSlider();



        }, interstitial_ad_for * 1000);


    }
    function startSlider() {
        let interstitial_ad = localStorage.getItem("interstitial_ad");
        setTimeout(function () {
            openModal();
        }, (interstitial_ad * 60) * 1000);
    }
    // async function onChangeColor(e) {

    //     // console.log();

    //     if (e.target.checked) {
    //         $(`.progress span`).css("color", "#90ee90");
    //         $('body').addClass('dark-preview');
    //         $('body').removeClass('white-preview');
    //         setColor("#90ee90");
    //     } else {
    //         $('body').addClass('white-preview');
    //         $('body').removeClass('dark-preview');
    //         $(`.progress span`).css("color", "black");
    //         setColor("#111");
    //     }


    // }
    async function calculatePercentage(access_token, event_id) {

     
         is_event_crash = localStorage.getItem("is_event_crash");

        const [per, response] = await Promise.all([
            getCashTrigger(access_token, event_id)
            , getClientProduct(access_token, event_id , is_event_crash)
        ]);

        setPercentage(Math.round(per));



        if (response.success === true) {
            setRecord(response.data);
            localStorage.setItem("is_event_crash",response.is_crash);
        }
    }

    async function logout() {

        localStorage.clear();

        window.location.reload("/barscreen");







    }

    const renderTime = (dimension, time) => {
        return (
            <div className="time-wrapper">
                <div className="time">{time}</div>
                <div>{dimension}</div>
            </div>
        );
    };
    const getTimeSeconds = (time) => {



        let crash_duration = cashDuration;

        let actualSec = (crash_duration * 60);


        let currentTime = moment().unix();

        let diff = currentTime - actualTime;


        let timeLeft = actualSec - diff;

        if (timeLeft === null || timeLeft <= 0) {

            timeLeft = 0;
        }







        return secondsToTime(timeLeft);






        // return secondsToTime(minuteSeconds - time);

    };

    function secondsToTime(e) {
        var h = Math.floor(e / 3600).toString().padStart(2, '0'),
            m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
            s = Math.floor(e % 60).toString().padStart(2, '0');

        return m + ':' + s;
        //return `${h}:${m}:${s}`;
    }

    const Slideshow = () => {
        console.log(121212);
        return (
            <div className="slide-container">
                <Zoom {...zoomOutProperties}>
                    {images.map((each, index) => (
                        <img key={index} style={{ width: "100%" }} src={each} />
                    ))}
                </Zoom>
            </div>
        );
    };

  

    if (isLogin() === true) {
        if (adDisplay === 0) {


            return (
                <div className="bar_sec bar_menu" onKeyDown={() => console.log('key pressed')}>
                 <div className="btn-container" style={{ right: "27px", color: color }}>
                                    <i className="fa fa-sign-out" onClick={() => logout()} aria-hidden="true" style={{ cursor: "pointer" }}> Logout</i>
                                </div>
                    <div className="container-fluid" style={{ paddingTop: "60px",paddingBottom: "20px" }}>
                    <div className="row ">
                <div className="col-lg-4 col-md-6 col-sm-12 col-6 progress-bars">
                <div className="App" style={{ width: 109, height: 119 , marginLeft: 152,position:'relative' }}>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    strokeWidth="12"
                    strokeLinecap="round"
                    styles={buildStyles({
                    textColor: "#ffffff",
                    pathColor: "#817940",
                    trailColor: "#d6cb6f",
                    textSize: "26px",
                    fontWeight: '700',
                    textAlign: "center",
                    position:'absolute',
                    x:"35",
                    y:"55",
                    text: {
                        x:"35",
                        y:"55"
                    },
                    })}
      />
      </div>
                <h4 style={{textAlign: "center"}}>CRASH RISK</h4>

                     {/* <div className="progress" data-percent={percentage} data-color="#f91016" style={{ width: percentage + "%" }}><span>CRASH RISK {percentage}%</span></div>
                            </div> */}
                    </div>
                 <div class="col-lg-4 col-md-6 col-sm-12 col-12   logo" style={{marginTop: "11px"}}>
					<div className="logo-bar">
                    {/* <img src={require('./assets/new-logo.png')} /> */}
					<Image image={'/images/new-logo.png'} style={{ width: "90px" }}  />
						<h2>CLUB DES JEUNES <span>FOUHREN</span></h2>
					</div>
                    
                    {is_event_crash == 2  ? <div className="crash-alert text-center" style={{ fontSize: "20px" ,marginTop: "10px" }} role="alert"><h3 style={{textTransform : "uppercase"}} className="crash-text">   Crash is Now Active</h3>
                                </div> : ""}


				</div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-6 progress-bars progress-bar-content">
             
                <div className="App">
                    
                    {startTimer===1? <CountdownCircleTimer
                        {...timerProps}
                        colors="#218380"
                        size="100"
                        strokeWidth="12"
                        duration={minuteSeconds}
                        initialRemainingTime={remainingTime % minuteSeconds}
                        onComplete={(totalElapsedTime) => {
                            if (totalElapsedTime) {
                                if (startTimer === 1) {
                                    // setStartTimer(0);
                                    // localStorage.setItem('is_interval_complete',1);
                                }
                                getTimeStamp();
                            }
                            return { shouldRepeat: true, delay: 1 } // repeat animation in 1.5 seconds
                        }}
                    >
                        {({ elapsedTime, color }) => (
                            <span style={{ color }}>
                                {renderTime(getTimeSeconds(elapsedTime))}
                            </span>
                        )}
                    </CountdownCircleTimer>:""}
                </div>
					<h4 style={{textAlign: "center"}}>Timer</h4>

                {/* <div className="App">
                    {startTimer===1? <  
                        {...timerProps}
                        colors="#abd399"
                        size="100"
                        strokeWidth="12"
                        duration={minuteSeconds}
                        initialRemainingTime={remainingTime % minuteSeconds}
                        onComplete={(totalElapsedTime) => {

                            if (totalElapsedTime){

                                cashTriggers();
                            }







                            return { shouldRepeat: true,delay:1, } // repeat animation in 1.5 seconds
                        }}
                    >
                        {({ elapsedTime, color }) => (
                            <span style={{ color }}>
                                {renderTime(getTimeSeconds(elapsedTime))}
                            </span>
                        )}
                    </CountdownCircleTimer>:""}
                </div> */}
                    </div>
                </div>
                        <div className="bar_panel" style={{ paddingTop: "10px" }}>
                            <div className="bar-items  bar_items">
                                
                             
                                {/* {percentage ? <div className="alert alert-danger text-center" style={{ fontSize: "20px" }} role="alert">
                                    Crash is Now Active
                                </div> : ""} */}
                                {/* <div className="progress-bar">

                                    <div className="progress" data-percent={percentage} data-color="#f91016" style={{ width: percentage + "%" }}><span>CRASH RISK {parseFloat(percentage.toFixed(1))}%</span></div>
                                </div> */}
                                <ul>
                                    {records.map(( item) => {
                                        return (
                                            <li  className='hover'   style={{ cursor: "pointer" }}>
                                            <span style={{ backgroundColor: item.color }}></span>{item.name}<strong style={{color: "#ffff" , fontWeight: "700"}}>{item.price}€</strong>
                                    </li>
                                        )
                                    })}


                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

            )
        }
        else {
             return(
                  <div style={{ margin: '20px', }}>
                  <Slideshow />
                  </div>
             )

        }
    } else {
        return (

            <Login type="Client" />
        )
    }

}
