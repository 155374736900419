
export function Image({ image  }) {




    return (
        <img src={image} alt="Product" style={{ width: "90px"}} />
    )

}
