
import Axios from "axios";
import * as Routes from "./apiRoutes";
import { toast } from 'react-toastify';

export async function signIn(user_name, password , type) {
    try {
        let response = await Axios.post(Routes.LOGIN_URL, {
            type,
            user_name,
            password,
        });
        let data = response.data;
        if (data.success) {
            return { status: true, message: "Authenticated", data: data };
        }
        else {
            displayMessages("error", "InCorrect Credential");
            return { status: false, message: "Unauthenticated" };
        }
    }
    catch (error) {
        if (error.response.status === 422) {
            // displayErrorMessage(error);
            return { status: false, message: "UnAuthenticated" };
        }
        return { status: false, message: "UnAuthenticated" };
    }


}


export async function signOut() {
    try {
        let response = await Axios.get(Routes.LOGOUT);
        return response.data;
    } catch (error) { }
}



export async function checkUser(access_token) {
    let isAuthenticated = await Axios.get(Routes.AUTH_CHECK, {
        headers: {
            'Authorization': `Bearer ${access_token}`
        },

    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let data = error.response.status;

            if (data === 401) {
                return { status: false, message: "Unauthenticated" };
            } else {
                return { status: false, message: "" };
            }
        });

    return isAuthenticated;
}
export async function getClientProduct(access_token, event_id , is_event_crash) {
    let isAuthenticated = await Axios.post(Routes.FETCH_PRODUCT, {
        event_id: event_id,
        is_event_crash:is_event_crash
    }, {
        headers: {
            'api-token': `${access_token}`
        },

    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let data = error.response.status;

            if (data === 401) {
                return { status: false, message: "Unauthenticated" };
            } else {
                return { status: false, message: "" };
            }
        });

    return isAuthenticated;
}

// export async function getBarProduct(access_token, event_id, bar_id) {
//     let isAuthenticated = await Axios.post(Routes.FETCH_BAR_PRODUCT, {
//         event_id: event_id,
//         bar_id: bar_id,
//     }, {
//         headers: {
//             'api-token': `${access_token}`
//         },

//     })
//         .then((response) => {
//             return response.data;
//         })
//         .catch((error) => {
//             let data = error.response.status;

//             if (data === 401) {
//                 return { status: false, message: "Unauthenticated" };
//             } else {
//                 return { status: false, message: "" };
//             }
//         });

//     return isAuthenticated;
// }

export async function getBarProducts(access_token , event_id, bar_id , cycle_load = null , price_cycle_id = null , last_cycle_id =null , manual_crash , auto_crash) {
    let isAuthenticated = await Axios.post(Routes.FETCH_BAR_PRODUCTS, {
        event_id: event_id,
        bar_id: bar_id,
        cycle_load,
        price_cycle_id,
        last_cycle_id,
        manual_crash,
        auto_crash
    }, {
        headers: {
            'accept':'application/json',
            'api-token': `${access_token}`
        },

    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let data = error.response.status;

            if (data === 401) {
                return { status: false, message: "Unauthenticated" };
            } else {
                return { status: false, message: "" };
            }
        });

    return isAuthenticated;
}

// Cash Trigger
export async function getCashTrigger(access_token, event_id) {
    let isAuthenticated = await Axios.post(Routes.FETCH_CASH_TRIGGER, {
        event_id: event_id,
    }, {
        headers: {
            'api-token': `${access_token}`
        },

    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let data = error.response.status;

            if (data === 401) {
                return { status: false, message: "Unauthenticated" };
            } else {
                return { status: false, message: "" };
            }
        });

    return isAuthenticated;
}

export async function getCashBarTrigger(access_token, event_id,bar_id) {

    let isAuthenticated = await Axios.post(Routes.FETCH_CASH_BAR_TRIGGER, {
        event_id: event_id,
        bar_id: bar_id,
    }, {
        headers: {
            'api-token': `${access_token}`
        },

    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let data = error.response.status;

            if (data === 401) {
                return { status: false, message: "Unauthenticated" };
            } else {
                return { status: false, message: "" };
            }
        });

    return isAuthenticated;
}

export async function triggerCash(access_token, event_id, bar_id, trigger, timeNow, actualSec,type , manual_crash , auto_crash) {
    let isAuthenticated = await Axios.post(Routes.SAVE_CASH_TRIGGER, {
        event_id: event_id,
        bar_id: bar_id,
        trigger: trigger,
        timeNow: timeNow,
        actualSec: actualSec,
        type: type,
        manual_crash:manual_crash,
        auto_crash : auto_crash


    }, {
        headers: {
            'api-token': `${access_token}`
        },

    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            let data = error.response.status;

            if (data === 401) {
                return { status: false, message: "Unauthenticated" };
            } else {
                return { status: false, message: "" };
            }
        });

    return isAuthenticated;
}

export async function saveOrder(orderItem,price_cycle_id, price_rounding , event_id, bar_id , last_cycle_id , manual_crash , auto_crash) {
    try {
        let response = await Axios.post(Routes.ORDER_URL, {
            orderItem,
            event_id,
            bar_id,
            price_cycle_id,
            last_cycle_id,
            price_rounding,
            manual_crash,
            auto_crash
        });
        let data = response.data;
        if (data.success) {
            return { status: true, data: data , auto_crash :data.auto_crash };
        }
        else {

            return { status: false, message: "Error While Saving Order" };
        }
    }
    catch (error) {
        if (error.response.status === 422) {
            // displayErrorMessage(error);
            return { status: false, message: "UnAuthenticated" };
        }
        return { status: false, message: "UnAuthenticated" };
    }


}


export async function saveTimer(time, event_id, actualSec) {
    try {
        let response = await Axios.post(Routes.TIME_URL, {
            time,
            event_id,
            actualSec

        });
        let data = response.data;
        if (data.success) {
            return { status: true, data: data };
        }
        else {

            return { status: false, message: "Error While Saving Order" };
        }
    }
    catch (error) {
        if (error.response.status === 422) {
            // displayErrorMessage(error);
            return { status: false, message: "UnAuthenticated" };
        }
        return { status: false, message: "UnAuthenticated" };
    }


}
export async function getTimer(event_id) {
    try {
        let response = await Axios.post(Routes.GET_TIME_URL, {

            event_id,


        });
        let data = response.data;
        if (data.success) {
            return { status: true, data: data };
        }
        else {

            return { status: false, message: "Error While Saving Order" };
        }
    }
    catch (error) {
        if (error.response.status === 422) {
            // displayErrorMessage(error);
            return { status: false, message: "UnAuthenticated" };
        }
        return { status: false, message: "UnAuthenticated" };
    }


}
export async function getEventCrash(event_id) {
    try {
        let response = await Axios.post(Routes.GET_EVENT_CRASH_URL, {

            event_id,


        });
        let data = response.data;
        if (data.success) {
            return { status: true, data: data };
        }
        else {

            return { status: false, message: "Error While Saving Order" };
        }
    }
    catch (error) {
        if (error.response.status === 422) {
            // displayErrorMessage(error);
            return { status: false, message: "UnAuthenticated" };
        }
        return { status: false, message: "UnAuthenticated" };
    }


}
export async function getNotification(event_id) {
    try {
        let response = await Axios.post(Routes.GET_TIME_NOTIFICATION, {

            event_id,


        });
        let data = response.data;
        if (data.success) {
            localStorage.setItem("is_event_crash",data.is_crash);
            console.log(data.is_crash);
            return { status: true, data: data };
        }
        else {

            return { status: false, message: "Error While Saving Order" };
        }
    }
    catch (error) {
        if (error.response.status === 422) {
            // displayErrorMessage(error);
            return { status: false, message: "UnAuthenticated" };
        }
        return { status: false, message: "UnAuthenticated" };
    }


}
export async function getEvent(event_id, access_token) {
    try {
        let response = await Axios.post(Routes.FETCH_EVENT, {

            event_id,


        }, {
            headers: {
                'api-token': `${access_token}`
            },

        });
        let data = response.data;
        if (data.success) {
            return { status: true, data: data };
        }
        else {

            return { status: false, message: "Error While Saving Order" };
        }
    }
    catch (error) {
        if (error.response.status === 422) {
            // displayErrorMessage(error);
            return { status: false, message: "UnAuthenticated" };
        }
        return { status: false, message: "UnAuthenticated" };
    }


}
export async function getAds(event_id, access_token) {
    try {
        let response = await Axios.post(Routes.FETCH_ADS, {

            event_id,


        }, {
            headers: {
                'api-token': `${access_token}`
            },

        });
        let data = response.data;

        return data;

    }
    catch (error) {
        if (error.response.status === 422) {
            // displayErrorMessage(error);
            return { status: false, message: "UnAuthenticated" };
        }
        return { status: false, message: "UnAuthenticated" };
    }


}


export function displayMessages(error, message) {

    return toast[error](message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}


