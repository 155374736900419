/* eslint-disable no-undef */
import React, { ChangeEvent, useState, useEffect } from 'react';
import { Image } from '../components/Image';
import $ from "jquery";

import moment from "moment";
import { getBarProduct,getBarProducts, displayMessages, saveOrder, getCashBarTrigger, triggerCash, saveTimer, getNotification, getEvent } from '../utils/ApiMethod';
import { useRecoilState } from 'recoil';
import { cashTrigger } from '../utils/atom';
import {  isLogin } from '../utils/Helper';
import Login from './Login';

import {
    CountdownCircleTimer } from 'react-countdown-circle-timer';
    import {
        CircularProgressbar,
        buildStyles
      } from "react-circular-progressbar";
const timerProps = {
    isPlaying: true,
    size: 120,
    strokeWidth: 6
};




export default function Bar() {
    const [percentage, setPercentage] = useState(0);
    const [access_token, seToken] = useState("");
    const [event_id, setEventID] = useState(0);
    const [price_cycle_id, setPriceCycleId] = useState(0);
    const [bar_id, setBarID] = useState(0);
    const [loading, setLoading] = useState(false);
    const [items, setItem] = useState([]);
    const [receiveAmount, setReceiveAmount] = useState(0);
    const [returnAmount, setReturnAmount] = useState(0);
    const [color, setColor] = useState("#111");
    const [textSize, settextSize] = useState("12");

    const [records, setRecord] = useState([]);

    const [cash_trigger, setTrigger] = useRecoilState(cashTrigger);
    const [minuteSeconds, setMintSecond] = useState(0);
    const [remainingTime, setRemainingTime] = useState(0); // use UNIX timestamp in seconds

    // const [manual_crash, setManualCrash] = useState(0);
    // const [autoCrash, setAutoCrash] = useState(0);
    
    const [startTimer,setStartTimer]=useState(0);
    const [pageLoad,setPageLoad]=useState(0);
    const [crashLoading,setCrashLoading]=useState(false);
    const [crashTriggered,setCrashTriggered]=useState(false);
    const [actualTime,setActualTime]=useState(0);
    const [cashDuration,setCasDuration]=useState(0);

    const [searchString, setSearchString] = useState(0);

    let [orderAmount, setOrderAmount] = useState(0);

    const [showResults, setShowResults] = React.useState(false)

    const { products } = records;
    const [cartItems, setCartItems] = useState([]);
    useEffect(() => {
        async function preInfoCall() {




            let token = localStorage.getItem("jwt");
            let eventID = localStorage.getItem("event_id");
            let barID = localStorage.getItem("bar_id");
            let crash_risk = localStorage.getItem("crash_risk");
            let crash_duration = localStorage.getItem("crash_duration");
            let calculation_interval = localStorage.getItem("calculation_interval");
            setCasDuration(crash_duration);
            let remaining_time = localStorage.getItem("remaining_time");
            let time = parseInt(remaining_time);
            let text_Size = localStorage.getItem("text_Size");
            let screen_color = localStorage.getItem("screen_color");

            if (screen_color ==="DarkMode") {
                $(`.progress span`).css("color", "#90ee90");
                $('body').addClass('dark-preview');
                $('body').removeClass('white-preview');
                setColor("#90ee90");
            } else {
                $('body').addClass('white-preview');
                $('body').removeClass('dark-preview');
                $(`.progress span`).css("color", "black");
                setColor("#111");
            }
            settextSize(text_Size);


            setTrigger(crash_risk);
            // setMintSecond(isNaN(time) ? (crash_duration * 60) : parseInt(remaining_time));
            // setRemainingTime(isNaN(time) ? (crash_duration * 60) : parseInt(remaining_time));

            let accessToken = "";
           

            if (access_token === "") {
                if (token !== null) {

                    seToken(token);
                    setEventID(eventID)
                    setBarID(barID)
                }
            } else {
                accessToken = token;
            }

            if (accessToken !== "") {
               let   price_cycle_id =  localStorage.getItem('price_cycle_id');
               let   last_cycle_id =  localStorage.getItem('last_cycle_id');
               let   manual_crash =  localStorage.getItem('manual_crash');
               let   autoCrash =  localStorage.getItem('auto_crash');

                let response = await getBarProducts(accessToken, parseInt(eventID), parseInt(barID) , false , price_cycle_id , last_cycle_id , manual_crash , autoCrash);

                if (response.success === true) {
                    localStorage.setItem('last_cycle_id', response.last_cycle_id);
                    localStorage.setItem('price_cycle_id', response.price_cycle_id);
                    setRecord(response.data.bervages);
            
                    calculatePercentage();

                    storeTimeStamp();
                    // setInterval(() => {
                    //     calculatePercentage(accessToken, eventID);
                    // }, 5000);


                } else {

                }
            }


        }

        preInfoCall();
        initFirebase();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access_token, bar_id, event_id, seToken]);

    var initFirebase = function () {
        firebase.database().ref("/notifications").on("value", function (snapshot) {
            checkNotification();
        });
    }



    async function logout() {
        localStorage.clear();
        window.location.reload("/barscreen");
    }

    async function checkNotification() {

        if (pageLoad===0){
            let event_id = localStorage.getItem('event_id');
            let token = localStorage.getItem("jwt");

            let res = await getNotification(event_id);

            let type=res.data.data.type;

            if (type ==="Event Update"){

                let event =await getEvent(event_id, token);
                localStorage.setItem('crash_risk', event.data.data.crash_risk);
                localStorage.setItem('crash_duration', event.data.data.crash_duration);
                localStorage.setItem('text_Size', event.data.data.text_size);
                localStorage.setItem('screen_color', event.data.data.screen_color);
                if (event.data.data.screen_color === "DarkMode") {
                    $(`.progress span`).css("color", "#90ee90");
                    $('body').addClass('dark-preview');
                    $('body').removeClass('white-preview');
                    setColor("#90ee90");
                } else {
                    $('body').addClass('white-preview');
                    $('body').removeClass('dark-preview');
                    $(`.progress span`).css("color", "black");
                    setColor("#111");
                }

                settextSize(event.data.data.text_size);
                setTrigger(event.data.data.crash_risk);

            }
            // if (type ==="Beverage Update"){
            //     price_cycle_id =  localStorage.getItem('price_cycle_id')

            //     let response = await getBarProducts(token, parseInt(event_id), parseInt(localStorage.getItem('bar_id')) ,true ,price_cycle_id );



            //     if (response.success === true) {
            //         setRecord(response.data.bervages);




            //     }

            // }
            if (type ==="Order"){
                calculatePercentage();

            }

        }else{
            setPageLoad(1);
        }









    }
    // async function setAmount(item) {
    //     console.log(orderAmount);
    //     setShowResults(true);
    //     let productID = item.id;
    //     setOrderAmount(orderAmount + parseFloat(item.sale_price));
    //     setReceiveAmount(orderAmount + parseFloat(item.sale_price));

    //     let index = items.findIndex(obj => obj.id === productID);

    //     if (index >= 0) {
    //         const newItems = [...items];
    //         newItems[index].qty += 1;

    //         setItem(newItems);

    //     } else {
    //         let obj = {
    //             ...item,
    //             qty: 1,
    //         }
    //         setItem(oldArray => [...oldArray, obj]);
    //     }

    //     // setTimeout(() => {
    //     //     setShowResults(false);
    //     //   }, 2000);



    // }

    async function deleteOrder() {
        setCartItems([]);
        setReturnAmount(0);
        setOrderAmount(0);
        setReceiveAmount(0);

    }

    async function calculatePercentage() {

        if (event_id===0 && bar_id===0){
            return;
        }

        const [per, response] = await Promise.all([
            getCashBarTrigger(access_token, event_id, bar_id)
        ]);



        if (per.status===false){
            console.log('crash false');
        }else{
            let is_crash = localStorage.getItem('is_crash');
            if(parseInt(is_crash)===3){
                setPercentage(0);
            }else{
                console.log('crash'+Math.round(per));
                setPercentage(Math.round(per));
            }
        }





        if (percentage === 0) {


        }

        if (response.success === true) {
            setRecord(response.data.bervages);

        }





    }


    async function storeTimeStamp() {

        let timeNow = moment().unix();
        let event_id=localStorage.getItem('event_id');
        let crash_duration = localStorage.getItem("crash_duration");
        let calculation_interval = localStorage.getItem("calculation_interval");
        let is_event_crash = localStorage.getItem("manual_crash");
        setCasDuration(crash_duration);
        let actualSec = (crash_duration * 60);
        if(is_event_crash != 0){
            console.log('is_event_crash'+is_event_crash);
            setCasDuration(calculation_interval);
             actualSec = (calculation_interval * 60);
        }

        console.log(actualSec);

        // setMintSecond(0);
        // setRemainingTime(0);


        let res = await saveTimer(timeNow, event_id, actualSec);

        let oldTime=res.data.data.time;
        let currentTime = moment().unix();

       let diff= currentTime-oldTime;
        console.log(currentTime - timeNow);

        let timeLeft = actualSec - diff;
        console.log(timeLeft);

        setActualTime(oldTime);

        if (timeLeft>0){
            setMintSecond(timeLeft);
            setRemainingTime(0);
            setStartTimer(1);
        }
        else{
            setMintSecond(actualSec);
            setRemainingTime(0);
            setStartTimer(1);
        }
    }
    async function placeOrder() {

        if (cartItems.length === 0) {

            return displayMessages("error", "Item Required");
        } else {

            if (receiveAmount === 0) {
                return displayMessages("error", "No Amount Received Yet");
            }
            console.log(orderAmount);
            let return_amount = orderAmount - receiveAmount;
            console.log(return_amount);
            if (return_amount !== returnAmount) {
                if (receiveAmount === 0) {
                    return displayMessages("error", "No Return Amount selected Yet");
                }
                return displayMessages("error", `Return Amount Should me ${return_amount}`);
            }

            let orderItem = {
                items: cartItems,
                orderAmount: orderAmount,
                receiveAmount: receiveAmount,
                returnAmount: returnAmount
            }


            setLoading(true);
            let price_cycle_id = localStorage.getItem('price_cycle_id');
            let last_cycle_id = localStorage.getItem('last_cycle_id');
            let price_rounding = localStorage.getItem('price_rounding');
            let   manual_crash =  localStorage.getItem('manual_crash');
            let   autoCrash =  localStorage.getItem('auto_crash');

            let response = await saveOrder(orderItem, price_cycle_id , price_rounding , event_id, bar_id , last_cycle_id , manual_crash , autoCrash);
            setLoading(false);

            if (response.status === true) {
                if(response.auto_crash == 1){
                    setPercentage(0);
                    displayMessages("success", "Crash Triggered  Successfully");
                    setCrashTriggered(true);
                    localStorage.setItem('auto_crash', response.auto_crash);

                    let price_cycle_id =  localStorage.getItem('price_cycle_id');
                    let last_cycle_id =  localStorage.getItem('last_cycle_id');
                    let manual_crash = localStorage.getItem("manual_crash");
                    let   autoCrash =  localStorage.getItem('auto_crash');
                    let   calculation_interval =  localStorage.getItem('calculation_interval');
                    setCasDuration(calculation_interval);
                   
                    let resp = await getBarProducts(access_token , event_id, bar_id , true , price_cycle_id ,last_cycle_id , manual_crash , autoCrash  );




                    if (resp.success === true) {
                        localStorage.setItem('price_cycle_id', resp.price_cycle_id);
                        localStorage.setItem('last_cycle_id', resp.last_cycle_id);
                        setRecord(resp.data.bervages);

                    }

                }
                setReturnAmount(0);
                setOrderAmount(0);
                setReceiveAmount(0);
                setItem([]);
                setCartItems([]);
                displayMessages("success", "Order Create Successfully");
                calculatePercentage();

            }

        }




    }


    async function onChangeColor(e) {



        if (e.target.checked) {
            $(`.progress span`).css("color", "#90ee90");
            $('body').addClass('dark-preview');
            $('body').removeClass('white-preview');
            setColor("#90ee90");
        } else {
            $('body').addClass('white-preview');
            $('body').removeClass('dark-preview');
            $(`.progress span`).css("color", "black");
            setColor("#111");
        }


    }






    async function deleteProduct(e) {
        e.preventDefault();
        if (e.keyCode === 32 && items.length !== 0) {
            // $('.bar_sec').attr("tabindex", 1).focus();
            $('#test').focus();
            setReturnAmount(0);
            setOrderAmount(0);
            setReceiveAmount(0);
            setItem([]);
        }

    }
    async function deleteItem (id) {

      let  index = cartItems.findIndex(x => x.id === id);

        let price = cartItems[index].qty * cartItems[index].sale_price;

        const newList = cartItems.filter((item) => item.id !== id);

        setItem(newList);
        setOrderAmount(orderAmount - price);
        setReceiveAmount(orderAmount - price);

    }

    // Trigger Crash

    async function cashTriggers(type) {



        // if (percentage>0){
            let manual_crash = localStorage.getItem("manual_crash");
            let autoCrash = localStorage.getItem("auto_crash");
            let timeNow = moment().unix();
            let event_id = localStorage.getItem('event_id');
            let calculation_interval = localStorage.getItem("calculation_interval");
            let crash_duration = localStorage.getItem("crash_duration");
            setCasDuration(crash_duration);

            let actualSec = (crash_duration * 60);
           

            if(manual_crash == 1){
                setStartTimer(0);
                console.log(calculation_interval);
                setCasDuration(calculation_interval);
                localStorage.setItem("manual_crash", 2);
                actualSec = (calculation_interval * 60);
            }


          

          

            if (type ==="Manual"){
                localStorage.setItem("manual_crash", 1);
                setCrashLoading(true);
            }else{
                if(autoCrash == 1){
                    localStorage.setItem("auto_crash", 2);
                    setCrashTriggered(true);
                    setCasDuration(calculation_interval);
                    actualSec = (calculation_interval * 60);
                }else{
                    setCrashTriggered(false);
                    localStorage.setItem("auto_crash", 0);
                    setStartTimer(0);
                }
            }


            if(manual_crash && manual_crash == 2){
                        localStorage.setItem("is_crash", 0);
                        localStorage.setItem("manual_crash", 0);
            }

            manual_crash = localStorage.getItem("manual_crash");
            autoCrash = localStorage.getItem("auto_crash");

            let trigger = await triggerCash(access_token, event_id, bar_id, percentage, timeNow, actualSec, type , manual_crash , autoCrash);
                console.log('crash type'+type);
                if (trigger.success === true) {
                  

                    if (trigger.time !== null) {

                        let time = trigger.time;


                        let oldTime = time.time;
                        setActualTime(oldTime);
                        let currentTime = moment().unix();

                        let diff = currentTime - oldTime;


                        let timeLeft = actualSec - diff;
                        let is_crash = localStorage.getItem('is_crash');
                        let  manual = localStorage.getItem("manual_crash");
         
                        // if (parseInt(is_crash) === 3) {

                        //     localStorage.setItem("is_crash", 0);
                        // }


                       if(manual == 0){
                           setMintSecond(timeLeft);
                       }else{
                        setMintSecond(calculation_interval * 60);
                       }
                        setRemainingTime(0);
                        setStartTimer(1);
                        initFirebase();
                        setCrashTriggered(false);
                        calculatePercentage();
                    }

                //    if(trigger.data!==null){

                //        setPercentage(0);


                //        displayMessages("success", "Crash Triggered  Successfully");

                //        let is_crash = localStorage.getItem('is_crash');
                //        if (parseInt(is_crash)===1){

                //            localStorage.setItem("is_crash", 3);

                //        }




                //    }

                    if (trigger.crash===0){

                        displayMessages("success", "Crash Triggered Set Manually");
                        localStorage.setItem("is_crash", 1);
                        setCrashLoading(false);
                        setCrashTriggered(true);

                    }
                    let price_cycle_id =  localStorage.getItem('price_cycle_id');
                    let last_cycle_id =  localStorage.getItem('last_cycle_id');
                    let manual_crash = localStorage.getItem("manual_crash");
                    let   autoCrash =  localStorage.getItem('auto_crash');
                     
                    let response = await getBarProducts(access_token , event_id, bar_id , true , price_cycle_id ,last_cycle_id , manual_crash , autoCrash  );




                    if (response.success === true) {
                        localStorage.setItem('price_cycle_id', response.price_cycle_id);
                        localStorage.setItem('last_cycle_id', response.last_cycle_id);
                        setRecord(response.data.bervages);

                    }

                }
    // }else{


    //     if(startTimer===1){
    //         setStartTimer(0);
    //     }

    //         calculatePercentage();


    //      await   storeTimeStamp();
    // }






    }
   

    const renderTime = (dimension, time) => {
        return (
            <div className="time-wrapper">
                <div className="time">{time}</div>
                <div>{dimension}</div>
            </div>
        );
    };
    const getTimeSeconds = (time) => {


        let crash_duration = cashDuration;
        let actualSec = (crash_duration * 60);
        let currentTime = moment().unix();
        let diff = currentTime - actualTime;
        let timeLeft = actualSec - diff;
        if (timeLeft === null || timeLeft<=0){

            timeLeft=0;
        }
        return secondsToTime(timeLeft);
    };

    

    function secondsToTime(e){
        var h = Math.floor(e / 3600).toString().padStart(2, '0'),
            m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
            s = Math.floor(e % 60).toString().padStart(2, '0');
        return  m + ':' + s;
        //return `${h}:${m}:${s}`;
    }

    const handleOnChange = (e) => {
        console.log(e.target.value);
     }

    const onAdd = (product) => {
        setShowResults(true);
        const exist = cartItems.find((x) => x.id === product.id);
        if (exist) {
          setCartItems(
            cartItems.map((x) =>
              x.id === product.id ? { ...exist, qty: exist.qty + 1 } : x
            )
          );
        } else {
          setCartItems([...cartItems, { ...product, qty: 1 }]);
        }
        setOrderAmount(orderAmount + parseFloat(product.price));
        setReceiveAmount(orderAmount + parseFloat(product.price));
      };
      
      const onRemove = (product) => {
        const exist = cartItems.find((x) => x.id === product.id);
        if (exist.qty === 1) {
          setCartItems(cartItems.filter((x) => x.id !== product.id));
        } else {
          setCartItems(
            cartItems.map((x) =>
              x.id === product.id ? { ...exist, qty: exist.qty - 1 } : x
            )
          );
        }
        setOrderAmount(orderAmount - parseFloat(product.price) );
        setReceiveAmount(orderAmount - parseFloat(product.price) );
      };
      
      const removeItem = (product , price) => {
        cartItems.find((x) => x.id === product.id);

        setCartItems(cartItems.filter((x) => x.id !== product.id));

  
        // let price = cartItems[index].qty * cartItems[index].sale_price;
        setOrderAmount(orderAmount - price );
        setReceiveAmount(orderAmount - price);
      };
    const [input, setInput] = useState('')
      const itemsPrice = cartItems.reduce((a, c) => a +  c.qty * c.sale_price, 0);
      const totalPrice = itemsPrice;

     console.log(localStorage.getItem("is_crash"));

    if (isLogin() === true) {

        return (
            <div id='test' className="bar_sec" tabIndex={1} onKeyDown={(e) => deleteProduct(e)}>
                
                <div className='container-fluid' style={{ paddingTop: "60px",paddingBottom: "20px" }} >
                <div className="row " style={  showResults ?{ width:"78%"} : {width:"100%"}}>
                <div className="col-lg-4 col-md-6 col-sm-12 col-6 progress-bars">
                <div className="App" style={{ width: 109, height: 119 , marginLeft: showResults ? 98 : 152,position:'relative' }}>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    strokeWidth="12"
                    strokeLinecap="round"
                    styles={buildStyles({
                    textColor: "#ffffff",
                    pathColor: "#817940",
                    trailColor: "#d6cb6f",
                    textSize: "26px",
                    fontWeight: '700',
                    textAlign: "center",
                    position:'absolute',
                    x:"35",
                    y:"55",
                    text: {
                        x:"35",
                        y:"55"
                    },
                    })}
      />
      </div>
      <h4 style={{textAlign: "center"}}>CRASH RISK</h4>

                     {/* <div className="progress" data-percent={percentage} data-color="#f91016" style={{ width: percentage + "%" }}><span>CRASH RISK {percentage}%</span></div>
                            </div> */}
                    </div>
                 <div class="col-lg-4 col-md-6 col-sm-12 col-12   logo" style={{marginTop: "11px"}}>
					<div className="logo-bar">
                    {/* <img src={require('./assets/new-logo.png')} /> */}
					<Image image={'/images/new-logo.png'} style={{ width: "90px" }}  />
						<h2>CLUB DES JEUNES <span>FOUHREN</span></h2>
					</div>

                    {/* {parseInt(localStorage.getItem("is_crash")) === 0 || localStorage.getItem("is_crash") === null ? '' : <h3>CRASH IS NOW ACTIVE</h3> } */}
				</div>
                <div class="col-lg-4 col-md-12 col-sm-12 col-6 progress-bars progress-bar-content">
                <div className="App">
                    
                    {startTimer===1? <CountdownCircleTimer
                        {...timerProps}
                        colors="#218380"
                        size="100"
                        strokeWidth="12"
                        duration={minuteSeconds}
                        initialRemainingTime={remainingTime % minuteSeconds}
                        onComplete={(totalElapsedTime) => {
                            if (totalElapsedTime){
                                cashTriggers();
                            }
                            return { shouldRepeat: true,delay:1, } // repeat animation in 1.5 seconds
                        }}
                    >
                        {({ elapsedTime, color }) => (
                            <span style={{ color }}>
                                {renderTime(getTimeSeconds(elapsedTime))}
                            </span>
                        )}
                    </CountdownCircleTimer>:""}
                </div>
					<h4 style={{textAlign: "center"}}>Timer</h4>

                {/* <div className="App">
                    {startTimer===1? <CountdownCircleTimer
                        {...timerProps}
                        colors="#abd399"
                        size="100"
                        strokeWidth="12"
                        duration={minuteSeconds}
                        initialRemainingTime={remainingTime % minuteSeconds}
                        onComplete={(totalElapsedTime) => {

                            if (totalElapsedTime){

                                cashTriggers();
                            }







                            return { shouldRepeat: true,delay:1, } // repeat animation in 1.5 seconds
                        }}
                    >
                        {({ elapsedTime, color }) => (
                            <span style={{ color }}>
                                {renderTime(getTimeSeconds(elapsedTime))}
                            </span>
                        )}
                    </CountdownCircleTimer>:""}
                </div> */}
                    </div>
                </div>
                </div>
                <div className="container-fluid">
                <div onClick={() => setShowResults(true)} class="cart-bar">
		<i   class="fa fa-shopping-cart"></i>
	</div>

    { showResults ?
    <div className="sideBar" style={{right : "0"}}>
		<i  onClick={() => setShowResults(false)} class="fa fa-times"></i>
	
        <div className="btn-container" style={{color:color}}>
                                <i className="fa fa-sign-out" onClick={() => logout()} aria-hidden="true" style={{ cursor: "pointer" }}> Logout</i>
                            </div>
                            
                            <h3>Current order</h3>
                            <span>{orderAmount.toFixed(2)}€</span>
                            <div className="price_sec">
                                <div className="table-div">
                                    <table className="table table-bordered" style={{color:color}}>
                                        <thead>
                                            <tr>

                                                <th scope="col">Beverages Name</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">X</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cartItems.map((item) => {
                                                return (
                                                    <tr key={item.id}>

                                                        <td>{item.name}</td>
                                                        <td>

                                                        <div className="input-groups" >
                                                <div className="input-group-prepend">
                                                    <button className="  price-btns"  type="button" onClick={() => onRemove(item)}>-</button>
                                                </div>
                                            
                                                <input type="text" className="price-input"  onChange={handleOnChange}   value={item.qty}  />
                                                <div className="input-group-prepend">
                                                    <button className="  price-btns" type="button" onClick={() => onAdd(item)}>+</button>
                                                </div>
                                                </div>
                                                        </td>
                                                        {/* <td>{item.qty}</td> */}
                                                        <td> {item.sale_price} €</td>
                                                        <td><i className='fa fa-trash' onClick={() => removeItem(item , (item.qty  * item.sale_price))}  style={{ color:"red",cursor:"pointer" }}></i></td>
                                                    </tr>

                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    </div>

                            </div>
                            <div className="btn_order">
                                <button className="green" onClick={() => placeOrder()}>{loading && (
                                    <i
                                        className="fa fa-refresh fa-spin"

                                    />
                                )}
                                    {loading && ' Place Order'}
                                    {!loading && 'Place Order'}</button>
                                <button className="red" onClick={() => deleteOrder()}>Delete current order</button>

                                {parseInt(localStorage.getItem("is_event_crash")) === 0 || localStorage.getItem("is_event_crash") === null?  <button className={crashTriggered?"white":"red"} disabled={crashTriggered} onClick={() => cashTriggers("Manual")}> {crashLoading && (
                                    <i
                                        className="fa fa-refresh fa-spin"
                                    />
                                )}{crashLoading && ' Trigger  Crash'}{!crashLoading && "Trigger Crash"} </button> : <button className={"white"} disabled={true} onClick={() => cashTriggers("Manual")}> Crash Triggered </button> }

                            </div>
	</div>

    : null }
                    <div className="bar_panel" style={{ paddingTop: "10px" }}>
                        <div className="bar-items  bar_items" style={  showResults ?{ width:"78%"} : {width:"100%"}}>
                            {/* <div className="progress-bar">
                                <div className="progress" data-percent={percentage} data-color="#f91016" style={{ width: percentage + "%" }}><span>CRASH RISK {percentage}%</span></div>
                            </div> */}
                            <ul>
                      {records.map((item) => {
                                    return (
					<li className='hover' onClick={() => onAdd(item)} key={item.id}  style={{ cursor: "pointer" }}>
					
							<span style={{ backgroundColor: item.color }}></span>{item.name}<strong style={{color: "#ffff" , fontWeight: "700"}}>{item.price}€</strong>
						
					</li>
				  )
                })}
					
				</ul>
                        </div>
                        {/* <div className="bar_orders">
                            <div className="btn-container" style={{color:color}}>
                                <i className="fa fa-sign-out" onClick={() => logout()} aria-hidden="true" style={{ cursor: "pointer" }}> Logout</i>
                            </div>
                            <div className="btn-container">
                                <i className="fa fa-moon-o" aria-hidden="true"></i>
                                <label className="switch btn-color-mode-switch">
                                    <input type="checkbox" name="color_mode" id="color_mode" onChange={onChangeColor}  value="1" />
                                    <label htmlFor="color_mode" data-on="" data-off="" className="btn-color-mode-switch-inner"></label>
                                </label>
                                <i className="fa fa-sun-o" aria-hidden="true"></i>
                            </div>
                            <h3>Current order</h3>
                            <span>{orderAmount.toFixed(2)}€</span>
                            <div className="price_sec">
                                <div className="table-div">
                                    <table className="table table-bordered" style={{color:color}}>
                                        <thead>
                                            <tr>

                                                <th scope="col">Beverages Name</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">X</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((item) => {
                                                return (
                                                    <tr key={item.id}>

                                                        <td>{item.name}</td>
                                                        <td>{item.qty}</td>
                                                        <td> {item.sale_price} €</td>
                                                        <td><i className='fa fa-trash' onClick={() => deleteItem(item.id)}  style={{ color:"red",cursor:"pointer" }}></i></td>
                                                    </tr>

                                                )
                                            })}


                                        </tbody>
                                    </table>
                                    </div>

                            </div>
                            <div className="btn_order">
                                <button className="green" onClick={() => placeOrder()}>{loading && (
                                    <i
                                        className="fa fa-refresh fa-spin"

                                    />
                                )}
                                    {loading && ' Place Order'}
                                    {!loading && 'Place Order'}</button>
                                <button className="red" onClick={() => deleteOrder()}>Delete current order</button>


                                {parseInt(localStorage.getItem("is_crash")) === 0 || localStorage.getItem("is_crash") === null?  <button className={crashTriggered?"white":"red"} disabled={crashTriggered} onClick={() => cashTriggers("Manual")}> {crashLoading && (
                                    <i
                                        className="fa fa-refresh fa-spin"

                                    />
                                )}{crashLoading && ' Trigger  Crash'}{!crashLoading && "Trigger Crash"} </button> : <button className={"white"} disabled={true} onClick={() => cashTriggers("Manual")}> Crash Trigger </button> }

                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )

    } else {
        return (

            <Login type="Bar" />
        )
    }




}
