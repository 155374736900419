import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../utils/Helper';
import { isBar } from '../utils/Helper';
import { isMenu } from '../utils/Helper';
import { useLocation } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const location = useLocation();
    const path = location.pathname;




    // if (isLogin()) {

    //     if (isBar() === false && isBar() === false) {
    //         // localStorage.clear();
    //         // return <Redirect to="/" />;
    //     }

    //     if (isBar() === true && path === "/barscreen") {

    //         return (

    //             // Show the component only when the user is logged in
    //             // Otherwise, redirect the user to /signin page
    //             <Route {...rest} render={props => (
    //                 isBar() ?
    //                     <Component {...props} />
    //                     : <Redirect to="/clientscreen" />
    //             )} />
    //         );
    //     }
    //     else if (isBar() === false && path === "/barscreen") {
    //         return <Redirect to="/menu" />;
    //     }

    //     if (isMenu() === true && path === "/clientscreen") {

    //         return (

    //             // Show the component only when the user is logged in
    //             // Otherwise, redirect the user to /signin page
    //             <Route {...rest} render={props => (
    //                 isMenu() ?
    //                     <Component {...props} />
    //                     : <Redirect to="/barscreen" />
    //             )} />
    //         );
    //     } else if (isMenu() === false && path === "/clientscreen") {

    //         return <Redirect to="/barscreen" />;
    //     }



    // } else {

    //     return (

    //         // Show the component only when the user is logged in
    //         // Otherwise, redirect the user to /signin page
    //         <Route {...rest} render={props => (
    //             isLogin() ?
    //                 <Component {...props} />
    //                 : <Redirect to="/" />
    //         )} />
    //     );
    // }

    return(
        <Route {...rest} render={props => (

                <Component {...props} />

        )} />
    )

};

export default PrivateRoute;
