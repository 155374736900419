import { atom, selector } from "recoil";
export const accessTokenState = atom({
    key: "accessTokenState",
    default: ""
});


export const cashTrigger = atom({
    key: "cashTrigger",
    default: 0
});

